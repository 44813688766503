<template>
  <v-navigation-drawer v-model="drawer" app dark temporary>
    <v-list>
      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        @click="onClick($event, link)"
      >
        <v-list-item-title v-text="$t('global.pages_titles.' + link.name)" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CoreDrawer",

  computed: {
    ...mapGetters(["links"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.setDrawer(val);
      },
    },
  },

  methods: {
    ...mapMutations(["setDrawer"]),
    onClick(e, item) {
      e.stopPropagation();

      // router.push(...) is equiavalent to <router-link :to="...">
      if (this.$route.path != item.href) {
        const scroll_to_content_hashtag = "home" != item.name ? "#content" : "";
        this.$router.push(item.href + scroll_to_content_hashtag);
      }
      this.setDrawer(false);
    },
  },
};
</script>
